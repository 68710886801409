import {  Grid, MenuItem, Popover, Select, Tooltip, Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import topleft from '../../assets/topleft.png';


// import css style
import './Header.css';

// import assets
import logo from '../../assets/logo.png';
import LanguageIcon from '@mui/icons-material/Language';
import Sidebar from '../Sidebar/Sidebar';
import MenuIcon from '@mui/icons-material/Menu';

const checkWichRouteIsActive = (route) => {
    switch (window.location.pathname.includes(route)) {
        case '/services':
                return 
            break;
    
        default:
            break;
    }
}

const handleChangeLanguage = (lang) =>{
    i18next.changeLanguage(lang);
     Cookies.set('i18next',lang);
     window.location.reload()
}

const Header = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [isSidebarOpen,setIsSidebarOpen] = useState(false);
    const [lang,setLang] = useState();
    const {t} = useTranslation();
    const location = useLocation();

    // console.log('tttt',t('header'))
    
    const [routes,setRoutes] = useState([
        {path: '/',text: t('header.main'),  className: ''},
        {path: '/whoweare',text: t('header.aboutus'), className: ''},
        // {path: '/services',text: t('header.services'), className: ''},
        {path: '/ourWork',text: t('header.ourWork'), className: ''},
        {path: '/contactus',text: t('header.contactus'), className: ''},

    ]);

    const handleToggleSidebar = () => {
        // console.log(isSidebarOpen)
        setIsSidebarOpen(prev => !prev);
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
        if(isSidebarOpen){
            document.body.style.overflowY = 'hidden';
        }else {
            document.body.style.overflowY = 'auto';
        }
    },[isSidebarOpen])
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    // change the active className on the nav links
    useEffect(() => {

            let copyroutes = [...routes];

            for(let i = 0 ; i < routes.length ; i++){
                if(location.pathname == routes[i].path ){
                    copyroutes[i].className = 'active';
                }else {
                    copyroutes[i].className = ''; 
                }
            }

            // reset the routes with new active class on the right route
            setRoutes(copyroutes);

    },[location.pathname]);

    // Add eventListener to hide sidebar on resizing the window
    useEffect(() => {

        const handleHideSidebar = () => {
            if(window.innerWidth >= 768) {
                setIsSidebarOpen(false);
            }
        }

        window.addEventListener('resize', handleHideSidebar);


        return () => {
            window.removeEventListener('resize', handleHideSidebar);
        }

    },[]);



  
    useEffect(() => {
      const language = Cookies.get('i18next') || 'en';
      // console.log('translation', t('header.home'));
      setLang(language);
    },[]);

    return <div className='navbar_main_container'>
            {/* <img src={topleft} loading='lazy' alt='background svg image' className='topleft_layout_image' /> */}

    <nav className='navbar_container'> 
    <div className='navbar_logo_container'>

        <Link to="/">
        <img loading='lazy' src={logo} alt="company logo" className='navbar_logo' />
        </Link>


    </div>

        <div className='navbar_links'>
            {
                routes?.map((route , index) => {
                    
                    return <Link 
                    key={index} 
                    to={route.path} 
                    className={route.className}>
                        {route.text}
                    </Link>
                })

            }

        </div>


        <div className='nav_icons'>
        <MenuIcon sx={{fontSize: '35px', marginRight: '20px'}} className="nav_menuIcon" onClick={() => handleToggleSidebar()} />
       
       <div>
       <Tooltip title={t('header.languages')} aria-describedby={id} variant="contained" onClick={handleClick}>
               <LanguageIcon sx={{fontSize: '35px',}} className="nav_langicon"/>
            </Tooltip>

            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            >

        <MenuItem 
        value={10} 
        sx={{background: Cookies.get('i18next') == 'ar' ? '#F2DA8C' : '' , width: '100px'}} 
        onClick={() => handleChangeLanguage('ar')}>{ t('header.arabic') }</MenuItem>

        <MenuItem 
        value={20} 
        sx={{background: Cookies.get('i18next') == 'en' ? '#F2DA8C' : '' , width: '100px'}}
        onClick={() => handleChangeLanguage('en')}> { t('header.english') } </MenuItem>

        </Popover>
       </div>


        </div>
    </nav>
        <Sidebar routes={routes} togglerFun={handleToggleSidebar} classname={ isSidebarOpen ? 'show_sidebar' : 'hide_sidebar' }/>
    </div>  
}

export default Header;