import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './Layout.css';
import topleft from '../../assets/topleft.png';
import rectangleBackground from '../../assets/rectangelBackground.png';

const Layout = ({children}) => {
    return <>
    <div className='layout_itself'>

        <Header />
        <div className="topleft_image_container">
            <img src={topleft} loading='lazy' alt='background svg image' className='topleft_layout_image' />
        </div>

        <div className='layout_childrens_container'>

            <img src={rectangleBackground} loading='lazy' alt="background svg image" className='rectangle_layout_image' />
            <img src={rectangleBackground} loading='lazy' alt="background svg image" className='rectangle_layout_bottom_image' />
            
        <div className='layout_childrens'>
            <div className='childrens_themseleves'>
            {children}
            </div>
            <Footer />
        </div>

      
        </div>


       
            {/* <h1>Lay out is right over here</h1> */}
          
    </div>
           
           </>
}

export default Layout;