
import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
import { base_url } from '../contstants';

const GetData = (url) => {

    const [data,setData] = React.useState(null);
    const [isLoading,setIsLoading] = React.useState(true);
    const [isError,setIsError] = React.useState(null);


    React.useEffect(() => {
        axios.get(`${ base_url + url + '?local=' + Cookies.get('i18next')}`)

        .then(data => {
            setData(data);
            console.log('data', data.data);
        })

        .catch(error => {
            setIsError(error);
        })

        .finally(() => {
            setIsLoading(false);
        })
    },[]);


    return {
        data ,
        isLoading,
        isError
    }
}

export default GetData;