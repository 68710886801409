
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import GetData from '../../hooks/GetData';
import icrLogo from '../../assets/icrlogo.png';
import Cookies from 'js-cookie';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import linkedin from '../../assets/linkedin.png';
import axios from 'axios';
import { base_url } from '../../contstants';
import { CircularProgress, Snackbar } from '@mui/material';

const Footer = () => {

    const { isLoading , data , isError } = GetData('get_all_site_infos');


    const {t} = useTranslation();
    const [email,setEmail] = React.useState('');
    const [sendingMsg,setSendingMsg] = React.useState(false);
    const [MsgSended,setMsgSended] = React.useState(false);
    const [MsgError,setMsgError] = React.useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        setSendingMsg(true);

        const data = {
            email,
        }
        axios.post(base_url + 'email_subscribe', data)
        .then(data => {
          //  console.log('sucess')
           setMsgSended(true);
            setEmail('');
           setTimeout(() => {
                setMsgSended(false);
           }, 8000);
        })
        .catch(error => {
            // console.log('error')
            setMsgError('حدث خطأ ما يرجى المحاولة لاحقا')
            
        })
        .finally(() => {
            setSendingMsg(false);
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setMsgSended(false);
      };
    
    return <>
    <footer className='footer'>

    <div className='top_footer_content'>

         <div className='left_contnet'>

            <div className='logo_container'>
                <img src={logo} alt="yarlla company" />
            </div>

            <div className='links_container'>
{/* 
                <div className='left_links'>
                    <Link to="/home">
                        <div className='link'>Home</div>
                    </Link>
                    <Link to="/home">
                        <div className='link'>About us</div>
                    </Link>
                    <Link to="/home">
                        <div className='link'>Contact us</div>
                    </Link>
                    <Link to="/home">
                        <div className='link'>Our works</div>
                    </Link>
                    <Link to="/home">
                        <div className='link'>FAQ</div>
                    </Link>
                </div> */}


                <div className='right_links'>
                    
                    <Link to="/">
                        <div className='link'>{t('header.main')}</div>
                    </Link>
                    
                    <Link to="/whoweare">
                        <div className='link'>{t('header.aboutus')}</div>
                    </Link>

                    <Link to="/contactus">
                        <div className='link'>{t('header.contactus')}</div>
                    </Link>

                    <Link to="/ourWork">
                        <div className='link'>{t('header.ourWork')}</div>
                    </Link>
                    
                    {/* <Link to="/home">
                        <div className='link'>FAQ</div>
                    </Link> */}
                
                </div> 

            </div>

         </div>

         <div className='right_contnet'>
            <h5>{t('footer.subscribe')}</h5>

            <form className='subscribe_form' onSubmit={(e) => handleSubmit(e)}>
                <input placeholder={t('footer.inputPlaceholder')} type="email" value={email}  onChange={(e) => setEmail(e.target.value)} />
                <button type='submit'>{t('footer.subscribebtn')} {sendingMsg && <CircularProgress size={15} color="success" />} </button>
            </form>

            <h5>{t('footer.followus')}</h5>

            <div className='follow_links_container'>
                
                <a href={data?.data?.data['رابط الفيس بوك']} target='_blank'>
                    <img src={facebook} alt="facebook icon" />
                </a>

                <a href={data?.data?.data['رابط الانستا غرام']} target='_blank'>
                    <img src={instagram} alt="instagram icon" />

                </a>

                <a href={data?.data?.data['رابط لينكد ان']} target='_blank'>
                <img src={linkedin} alt="linkedin icon" />

                </a>
            </div>
         </div>

    </div>
    
    <div className='bottom_footer_content'>
                    
            <a href='https://www.facebook.com/ICRCompanyPro' target={'_blank'}>
                <h5>
                    {t('all.deisignedByIcr')}
                    <img src={icrLogo} alt="ICR comapny logo" />
                </h5>
            </a>

                
            


            <a href='http://ideacodereality.icr@gmail.com' target={'_blank'}>
                <h6>{t('all.contacticr')}</h6>
                <h6>IdeaCodeReality.ICR@gmail.com</h6>
            </a>
            
            <h6>
                {t('all.copyrights')} {new Date().getFullYear()}
            </h6>
        
    </div>
    

    </footer>

    <Snackbar
          sx={{fontSize: '1.2rem'}}
        open={MsgSended}
        autoHideDuration={6000}
        handleClose={handleClose}
        message={ Cookies.get('i18next') == 'en' ? 
        "You are subscribed successfully"
        : 
        "تم الاشتراك بنجاح"}
      />
    </>
}

export default Footer;