import './App.css';
// import Routes from 'routes';
// import MainRoutes from './routes/MainRoutes';
import MainRoutes from './routes/index';
import NavigationScroll from './components/Layout/NavigationOnScroll';
import Layout from './components/Layout/Layout';
import CheckDetectedLanguage from './hooks/checkDetectedLanguage';

function App() {
  // const Routes = useRoutes([MainRoutes]);
  return (
    <div className='App'>
      <CheckDetectedLanguage>
      <NavigationScroll>
          <Layout>

          <MainRoutes />
          </Layout>
 
      </NavigationScroll>
      </CheckDetectedLanguage>
    </div>
  );
}

export default App;
