import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';

const Sidebar = ({routes , classname = 'show_sidebar' , togglerFun}) => {

    return <div className={`sidebar ${classname}`}>
        <div className='sidebar_content'>

            <div className='sidebar_content_fillside'>
                <div className='closeIcon'>
                    <IconButton onClick={togglerFun}>
                    <HighlightOffIcon />
                    </IconButton>
                </div>
                <div className='sidebar_content_routes'>
                        {     
                        routes?.map((route , index) => {
                                
                                return  <Link 
                                key={index}
                                to={route.path} 
                                className={route.className + ' sidebar_link'}
                                onClick={togglerFun}
                                >
                                    {route.text}
                                </Link>
                            })
                        }
                </div>

                <div className='closeIcon display-none'>
                    X
                </div>
            </div>

            <div className='sidebar_content_emptyside' onClick={(e) => { e.stopPropagation() ;togglerFun() }}></div>

        </div>
    </div>
}

export default Sidebar;