import { lazy } from 'react';

// project imports
import Loadable from '../components/Loadable/Loadable';
// import WorkDetails from '../pages/WorkDetaills/WorkDetails';
// import WorkDetails from '../pages/WorkDetaills/WorkDetaills';
// import AllServices from '../pages/AllServices/AllServices';
// import Contactus from '../pages/Contactus/Contactus';
// import Home from '../pages/Home/Home';

// import Services from '../pages/Services/Services'

const Home = Loadable(lazy(() => import('../pages/Home/Home')));
const Ourwork = Loadable(lazy(() => import('../pages/Ourwork/Ourwork')));
const ServiceDetails = Loadable(lazy(() => import('../pages/ServiceDetails/ServiceDetails')));
const Contactus = Loadable(lazy(() => import('../pages/Contactus/Contactus')));
const Sections = Loadable(lazy(() => import('../pages/Sections/Sections')));
const AboutUs = Loadable(lazy(() => import('../pages/Aboutus/AboutUs')));
const WorkSections = Loadable(lazy(() => import('../pages/WorkSections/WorkSections')));
const AllServices = Loadable(lazy(() => import('../pages/AllServices/AllServices')));
const WorkDetails = Loadable(lazy(() => import('../pages/WorkDetaills/WorkDetails')));

const MainRoutes = [

    {
        path:'/',
        element: <Home />,
        index: true
    },
    // {
    //     path: '',
    //     element: <Home />
    // },
    {
        path: '/ourWork',
        element: <Ourwork />,
    },
    {
        path: '/services/:id',
        element: <ServiceDetails />
    },
    {
        path: '/contactus',
        element: <Contactus />
    },
    {
        path: '/sections',
        element: <Sections />
    },
    {
        path: '/whoweare',
        element: <AboutUs />
    },
    {
        path: '/worksections/:id',
        element: <WorkSections />
    },
    {
        path: '/workdetails/:id',
        element: <WorkDetails />
    },
    {
        path: '/allservices/:id',
        element: <AllServices />
    }
    

    // children: [
    //     {
    //         path: '',
    //         element: <Home />
    //     },
    //     {
    //         path: 'services',
    //         element: <Services />
    //     }
    // ]

];

export default MainRoutes;
