import { useEffect, useMemo } from "react";
import Cookies from 'js-cookie';
import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

const languages = [
    {
      code:'en',
      name:'English',
      country_coode:'gb'
    },{
      code:'ar',
      name:'عربي',
      country_coode:'sa',
      dir:'rtl'
  }
  ]

  i18n
.use(initReactI18next) // passes i18n down to react-i18next
.use(LanguageDetector)
.use(HttpApi)
.init({
  supportedLngs:['en','ar']
  ,
  fallbackLng: "ar",
  detection:{
    order: [ 'cookie', 'localStorage', 'subdomain'],
    caches:['cookie'],
  },
  backend:{
    loadPath: '/assets/locales/{{lng}}/translation.json',
  },
  
 
});

function CheckDetectedLanguage({children}) {
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    const  currentLanguage = useMemo(() => languages.find( l => l.code === currentLanguageCode) ,[currentLanguageCode]) 


    useEffect(() => {

    document.dir = currentLanguage.dir || 'ltr';

    document.getElementsByTagName('html')[0].setAttribute('lang',currentLanguage.code || 'en') ;
  
    },[currentLanguage]);

    return <>
        {children}
    </>
}

export default CheckDetectedLanguage;